import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { SkillState } from '../../@types/skill';

const initialState: SkillState = {
  isLoading: false,
  error: false,
  skillsChart: []
};

const slice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSkills(state, action) {
      state.isLoading = false;
      state.skillsChart = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export function getSkillsChart() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('skills');
      // console.log('response', response.data);
      dispatch(slice.actions.getSkills(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
