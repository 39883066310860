// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 64, height: 64, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g>
          <path
            d="M55.2,17.2c-1.1,0-2.1-0.4-2.9-1.1c-5-4.6-12.5-7.2-20.4-7.2s-15.3,2.6-20.4,7.2c-1.8,1.6-4.5,1.4-6-0.4
            C4.1,14,4.2,11.3,5.9,9.7C12.4,3.7,22,0.3,32,0.3s19.5,3.5,26.1,9.5c1.7,1.6,1.9,4.3,0.3,6C57.6,16.7,56.4,17.2,55.2,17.2
            L55.2,17.2z"
            fill="#0B4569"
          />
          <path
            d="M3.1,28.9c-0.2-0.5-0.3-1.1-0.3-1.6c0-2.4,2-4.3,4.5-4.3c2,0,3.7,1.3,4.3,3.1l7.9,24.4l8-24.2
            c0.7-2,2.2-3.5,4.4-3.5h0.7c2.2,0,3.8,1.4,4.4,3.5l8,24.2l7.9-24.4c0.6-1.7,2-3.1,4.2-3.1c2.4,0,4.3,1.8,4.3,4.2
            c0,0.6-0.1,1.2-0.3,1.7L49.8,60.2c-0.8,2.2-2.4,3.6-4.5,3.6h-0.9c-2.1,0-3.7-1.3-4.5-3.6L32,37.3l-7.8,22.8
            c-0.8,2.3-2.4,3.6-4.5,3.6h-0.9c-2.1,0-3.8-1.4-4.5-3.6L3.1,28.9z"
            fill="#67BC6B"
          />
        </g>
      </svg>
    </Box>
  );
}
