// hooks
import { getCompanyPath } from 'utils/getImagePath';
// import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
// import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------
type MyLogoProps = {
  imageUrl: string;
};

export default function MyCompany({ imageUrl }: MyLogoProps) {
  //   const { user } = useAuth();
  //   alt={user?.displayName}
  //   color={user?.photoUrl ? 'default' : createAvatar(user?.displayName).color}
  return <MAvatar src={imageUrl} />;
}
