import { ReactElement } from 'react';

import { experimentalStyled as styled } from '@material-ui/core/styles';
import MyCompany from 'components/MyCompany';
import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  // borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

type RolebarProps = {
  title: string;
  subtitle: string;
  navigateTo: string;
  icon: string;
};

export default function DashboardRoleSidebar({
  title,
  subtitle,
  navigateTo,
  icon
}: RolebarProps): ReactElement {
  return (
    <Box sx={{ mt: 2, mb: 1, mx: 2.5 }}>
      <Link underline="none" component={RouterLink} to={navigateTo}>
        <AccountStyle>
          <MyCompany imageUrl={icon} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {subtitle}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>
    </Box>
    //   {user?.registeredRole === 3 && (
    //     <Box sx={{ mt: 2, mb: 1, mx: 2.5 }}>
    //       <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.company.new}>
    //         <AccountStyle>
    //           <MyCompany />
    //           <Box sx={{ ml: 2 }}>
    //             <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
    //               Crear institución educativa
    //             </Typography>
    //             <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    //               Completa tu perfil
    //             </Typography>
    //           </Box>
    //         </AccountStyle>
    //       </Link>
    //     </Box>
    //   )}
  );
}
