// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------
// color: '#1F4466'
const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  new: getIcon('ic_new'),
  pathways: getIcon('ic_pathways'),
  explore_1: getIcon('ic_explore_1'),
  explore_2: getIcon('ic_explore_2'),
  explore_3: getIcon('ic_explore_3'),
  following: getIcon('ic_following'),
  sharedWithYou: getIcon('ic_shared_with_you')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.community',
    items: [
      {
        title: 'menu.explore',
        path: PATH_DASHBOARD.explore.pathways,
        icon: ICONS.explore_3
      },
      {
        title: 'menu.following',
        path: PATH_DASHBOARD.explore.following,
        icon: ICONS.following
      },
      {
        title: 'menu.shared_with_me',
        path: PATH_DASHBOARD.explore.sharedWithYou,
        icon: ICONS.sharedWithYou
      }
    ]
  }
  // {
  //   subheader: 'Rutas Personales',
  //   items: [
  // {
  //   title: 'Ver todas',
  //   path: PATH_DASHBOARD.explore.pathwaysList,
  //   icon: ICONS.dashboard
  // }
  //   ]
  // }
  // {
  //   subheader: 'Aprendizaje Personal',
  //   items: [
  //     {
  //       title: 'Objetivos de Aprendizaje',
  //       path: PATH_DASHBOARD.explore.following,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
  // {
  //   subheader: 'Personal',
  //   items: [
  //     {
  //       title: 'panel de control',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     {
  //       title: 'Aprendizaje',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     {
  //       title: 'Contenido',
  //       path: PATH_DASHBOARD.general.content,
  //       icon: ICONS.calendar
  //     }
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
  //   ]
  // }
  // {
  //   subheader: '',
  //   items: [
  //     {
  //       title: 'Mi Aprendizaje',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     {
  //       title: 'Contenido',
  //       path: PATH_DASHBOARD.general.content,
  //       icon: ICONS.calendar
  //     }
  //   ]
  // }
  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Expertos',
  //   items: [
  // MANAGEMENT : USER
  // {
  //   title: 'Panel de Control',
  //   path: PATH_DASHBOARD.general.app,
  //   icon: ICONS.dashboard,
  //   children: [
  //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //     { title: 'account', path: PATH_DASHBOARD.user.account }
  //   ]
  // },

  // MANAGEMENT : E-COMMERCE
  // {
  //   title: 'e-commerce',
  //   path: PATH_DASHBOARD.eCommerce.root,
  //   icon: ICONS.cart,
  //   children: [
  //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     // { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //   ]
  // },

  // MANAGEMENT : BLOG
  // {
  //   title: 'Explorar',
  //   // path: PATH_DASHBOARD.blog.root,
  //   path: PATH_DASHBOARD.explore.pathways,
  //   icon: ICONS.chat
  // children: [
  //   { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //   { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //   { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  // ]
  // }
  // ]
  // },
  // {
  //   subheader: 'rutas',
  //   items: []
  // items: [
  //   {
  //     title: 'nueva',
  // path: PATH_DASHBOARD.general.app,
  //   path: PATH_DASHBOARD.pathway.new,
  //   icon: ICONS.new
  // }
  // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
  //   ]
  // }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar }
  //     // { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
];

export default sidebarConfig;
