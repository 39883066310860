import { faker } from '@faker-js/faker';
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { PathwayState } from '../../@types/pathway';

const initialState: PathwayState = {
  isLoading: false,
  error: false,
  pathways: [],
  followingPathways: [],
  myPathways: [],
  savedPathways: [],
  sharedPathways: [],
  pathway: null,
  publishedTopics: [],
  recentPosts: [],
  resources: [],
  hasMore: true,
  index: 0,
  step: 100
};

const slice = createSlice({
  name: 'pathway',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    cleanPathway(state) {
      state.pathway = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.pathways = action.payload;
    },
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.pathways = action.payload;
    },
    getFollowers(state, action) {
      state.isLoading = false;
      state.followingPathways = action.payload;
    },
    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },
    noHasMore(state) {
      state.hasMore = false;
    },
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.pathway = action.payload;
    },
    updatePathwaySuccess(state, action) {
      state.isLoading = false;
      state.pathway = action.payload;
    },
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    },
    postSaveSuccess(state, action) {
      state.isLoading = false;
      state.myPathways = [...state.myPathways, action.payload];
    },
    addTopicLearningSuccess(state, action) {
      const publishedTopics = state.publishedTopics.map((item) =>
        item.guid === action.payload.topicGuid
          ? {
              ...item,
              learnings: [
                {
                  explanation: action.payload.response.data.explanation
                }
              ]
            }
          : item
      );
      state.publishedTopics = publishedTopics;
    },
    addResourceLearningSuccess(state, action) {
      const publishedTopics = state.publishedTopics.map((item) =>
        item.guid === action.payload.topicGuid
          ? {
              ...item,
              resources: item.resources.map((resource: any) =>
                resource.guid === action.payload.resourceGuid
                  ? {
                      ...resource,
                      learnings: [
                        {
                          explanation: action.payload.response.data.explanation
                        }
                      ]
                    }
                  : resource
              )
            }
          : item
      );
      state.publishedTopics = publishedTopics;
    },
    deleteSpaceSuccess(state, action) {
      const idx = state.myPathways.map((item) => item.guid).indexOf(action.payload);
      state.myPathways = [...state.myPathways.slice(0, idx), ...state.myPathways.slice(idx + 1)];
    },
    getPublishedTopics(state, action) {
      state.isLoading = false;
      state.publishedTopics = action.payload;
    },
    getMyPathways(state, action) {
      state.isLoading = false;
      state.myPathways = action.payload.items;
    },
    getSavedPathways(state, action) {
      state.isLoading = false;
      state.savedPathways = action.payload.items;
    },
    getSharedPathways(state, action) {
      state.isLoading = false;
      state.sharedPathways = action.payload.items;
    },
    // https://stackoverflow.com/questions/60465636/update-object-key-using-destructuring
    updatePathwayFollowed(state, action) {
      state.isLoading = false;
      if (state.pathway != null) {
        state.pathway.followed = action.payload;
      }
    },
    updateIsFullView(state, action) {
      state.isLoading = false;
      if (state.pathway) {
        state.pathway.isFullView = action.payload;
      }
    },
    updateIsDraftView(state, action) {
      state.isLoading = false;
      if (state.pathway) {
        state.pathway.isDraftView = action.payload;
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

export function getAllPosts() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/all');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPostsInitial(index: number, step: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/blog/posts', {
      // const response = await axios.get('space/users', { params: { index, step } });
      const response = await axios.get('explore', { params: { index, step } });
      // const results = response.data.results.length;
      const results = response.data.items.length;
      // const { maxLength } = response.data;
      const { totalCount } = response.data;
      // dispatch(slice.actions.getPostsInitial(response.data.results));
      dispatch(slice.actions.getPostsInitial(response.data.items));

      // if (results >= maxLength) {
      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPost(pathwaySlug: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/pathways/${pathwaySlug}`);
      dispatch(slice.actions.getPostSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updatePathway(pathway: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/pathways', {
        slug: pathway.slug,
        title: pathway.title,
        description: pathway.currentKnowledge,
        currentKnowledge: pathway.currentKnowledge,
        public: pathway.public,
        content: pathway.content,
        imageUrl: pathway.imageUrl,
        collaborators: pathway.collaborators,
        published: pathway.publish,
        url: pathway.url,
        targetKnowledge: pathway.targetKnowledge,
        domainLevel: pathway.domainLevel,
        format: pathway.format,
        initial: parseInt(pathway.initial, 10),
        target: parseInt(pathway.target, 10),
        enableComments: pathway.comments,
        type: parseInt(pathway.type, 10),
        skills: pathway.skills
      });
      dispatch(slice.actions.updatePathwaySuccess(response.data));
      return response.data.slug;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
    return '';
  };
}

export function getRecentPosts(title: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function savePathway(pathway: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('pathways', {
        title: pathway.title,
        description: pathway.currentKnowledge,
        currentKnowledge: pathway.currentKnowledge,
        targetKnowledge: pathway.targetKnowledge,
        domainLevel: pathway.domainLevel,
        format: pathway.format,
        initial: parseInt(pathway.initial, 10),
        target: parseInt(pathway.target, 10),
        content: pathway.content,
        published: pathway.publish,
        enableComments: pathway.comments,
        public: pathway.public,
        imageUrl: pathway.imageUrl,
        type: parseInt(pathway.type, 10),
        collaborators: pathway.collaborators,
        readers: pathway.readers,
        url: pathway.url,
        skills: pathway.skills
      });
      dispatch(slice.actions.postSaveSuccess(response.data));
      return response.data.slug;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
    return '';
  };
}

export function uploadImagePathway(file: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();

      const extension = file.name.split('.').pop();

      const fileName = `${faker.datatype.uuid()}.${extension}`;

      formData.append('file', file, fileName);

      const response = await axios.post('documents/pathways', formData, config);

      return fileName;
      // dispatch(slice.actions.postSaveSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
    return null;
  };
}

export function deletePathway(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`Pathways/${pathwayGuid}`);
      dispatch(slice.actions.deleteSpaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function focusPathway(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.post(`Pathways/${pathwayGuid}/IsFocus/${true}`);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function archivePathway(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`Pathways/${pathwayGuid}/IsArchive/${true}`);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function addTopicLearning(pathwayGuid: string, topicGuid: string, content: string) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.post(`Pathways/${pathwayGuid}/Topics/${topicGuid}/Learnings`, {
        Explain: content
      });

      const obj = {
        topicGuid,
        response
      };

      dispatch(slice.actions.addTopicLearningSuccess(obj));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function addResourceLearning(
  pathwayGuid: string,
  topicGuid: string,
  resourceGuid: string,
  content: string
) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.post(
        `Pathways/${pathwayGuid}/Resources/${resourceGuid}/Learnings`,
        {
          Explain: content
        }
      );
      const obj = {
        topicGuid,
        resourceGuid,
        response
      };
      dispatch(slice.actions.addResourceLearningSuccess(obj));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getPublishedTopics(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`Explore/Pathways/${pathwayGuid}/Topics`);
      dispatch(slice.actions.getPublishedTopics(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updateIsFullView(pathwayGuid: string, isFullView: boolean) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.post(`Pathways/${pathwayGuid}/IsFullView/${isFullView}`);
      dispatch(slice.actions.updateIsFullView(isFullView));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updateIsDraftView(pathwayGuid: string, isDraftView: boolean) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.post(`Pathways/${pathwayGuid}/IsDraftView/${isDraftView}`);
      dispatch(slice.actions.updateIsDraftView(isDraftView));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getMyPathways() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('pathways/me');
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getMyPathways(response.data));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSharedPathways() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('pathways/sharedWithMe');
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getSharedPathways(response.data));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSavedForLaterPathways() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('pathways/saved');
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getSavedPathways(response.data));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cleanPathway() {
  const { dispatch } = store;
  dispatch(slice.actions.cleanPathway());
}

export function followPathway(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Followers/Pathway/${pathwayGuid}/Follow`);
      dispatch(slice.actions.updatePathwayFollowed(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unfollowPathway(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Followers/Pathway/${pathwayGuid}/Unfollow`);
      dispatch(slice.actions.updatePathwayFollowed(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowers() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('Followers/Pathways');

      dispatch(slice.actions.getFollowers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrganizationPathways(index: number, step: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('organization/pathways', { params: { index, step } });
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.items));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
