import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import pathwayReducer from './slices/pathway';
import skillReducer from './slices/skill';
import dashboardReducer from './slices/dashboard';
import organizationReducer from './slices/organization';
import topicReducer from './slices/topic';
import recallReducer from './slices/recall';
import suggestionReducer from './slices/suggestion';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  pathway: pathwayReducer,
  dashboard: dashboardReducer,
  organization: organizationReducer,
  skill: skillReducer,
  product: persistReducer(productPersistConfig, productReducer),
  topic: topicReducer,
  recall: recallReducer,
  suggestion: suggestionReducer
});

export { rootPersistConfig, rootReducer };
