import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'es',
    label: 'Español',
    icon: '/static/icons/ic_flag_es.svg'
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flags_us.svg'
  }
  // {
  //   value: 'de',
  //   label: 'German',
  //   icon: '/static/icons/ic_flag_de.svg'
  // },
  // {
  //   value: 'fr',
  //   label: 'French',
  //   icon: '/static/icons/ic_flag_fr.svg'
  // }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
