import { NavLink as RouterLink, useLocation, NavLinkProps } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useAuth from 'hooks/useAuth';
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: NavLinkProps;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const isHome = pathname === '/';

  return (
    <AppBar color="default" sx={{ boxShadow: 0 }}>
      {/* <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}> */}
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            Ts v2.1.0
          </Label> */}

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {isAuthenticated && (
            <Button variant="contained" component={RouterLink} to={PATH_DASHBOARD.root}>
              Panel de Control
            </Button>
          )}

          {!isAuthenticated && (
            <Button
              variant="text"
              color="inherit"
              sx={{ mr: 1 }}
              component={RouterLink}
              to={PATH_DASHBOARD.root}
            >
              Inicia sesión
            </Button>
          )}

          {!isAuthenticated && (
            <Button variant="contained" component={RouterLink} to={PATH_AUTH.register}>
              Regístrate
            </Button>
          )}

          {/* {!isAuthenticated && (
            <Button variant="contained" component={RouterLink} to={PATH_DASHBOARD.root}>
              Regístrate Gratis
            </Button>
          )} */}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
