// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 128, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="40.605" viewBox="0 0 150 40.605">
        <g id="wkntnw" transform="translate(-2340 365)">
          <path
            id="Union_3"
            data-name="Union 3"
            d="M35.339,15.712v-.068a9.385,9.385,0,0,1,9.707-9.307,9.291,9.291,0,0,1,9.636,9.239v.068a9.386,9.386,0,0,1-9.707,9.307A9.289,9.289,0,0,1,35.339,15.712Zm5-.136v.068a4.751,4.751,0,0,0,4.7,4.937,4.574,4.574,0,0,0,4.636-4.868v-.068a4.75,4.75,0,0,0-4.7-4.937A4.58,4.58,0,0,0,40.342,15.576ZM24.418,24.921a2.767,2.767,0,0,1-2.634-2.1L17.177,9.441l-4.6,13.377a2.765,2.765,0,0,1-2.635,2.1H9.4a2.835,2.835,0,0,1-2.662-2.1L.2,4.47A3.282,3.282,0,0,1,0,3.5,2.552,2.552,0,0,1,2.635,1a2.6,2.6,0,0,1,2.5,1.836l4.637,14.31L14.444,2.965a2.7,2.7,0,0,1,2.6-2.029h.4a2.668,2.668,0,0,1,2.6,2.029l4.671,14.177,4.631-14.3A2.572,2.572,0,0,1,31.818,1a2.484,2.484,0,0,1,2.536,2.469,3.583,3.583,0,0,1-.2,1L27.615,22.819a2.846,2.846,0,0,1-2.663,2.1Zm33.513-2.7V9.007a2.536,2.536,0,0,1,5.071,0v.8c.734-1.734,1.934-3.335,3.435-3.335a2.31,2.31,0,0,1,2.469,2.467,2.332,2.332,0,0,1-1.9,2.369c-2.57.6-4,2.569-4,6.171v4.737a2.536,2.536,0,1,1-5.071,0Zm24.847,1.364L78.34,17.477l-2.029,1.835v2.9a2.536,2.536,0,0,1-5.072,0l0-19.679a2.536,2.536,0,1,1,5.072,0V13.615l6.34-6a3.119,3.119,0,0,1,2.236-1.134,2.22,2.22,0,0,1,2.236,2.269,3.033,3.033,0,0,1-1.168,2.2l-4.007,3.43,4.77,6.439a2.667,2.667,0,0,1,.634,1.634,2.3,2.3,0,0,1-2.369,2.3A2.412,2.412,0,0,1,82.778,23.583Z"
            transform="translate(2340 -354.38)"
            fill="#67bc6b"
          />
          <path
            id="Union_4"
            data-name="Union 4"
            d="M57.982,40.143a2.194,2.194,0,0,1-1.6-2.1,2.028,2.028,0,0,1,2-2.068,2.392,2.392,0,0,1,.834.127,3.321,3.321,0,0,0,1.067.2,1.65,1.65,0,0,0,1.8-1.067l.167-.4-6.1-14.144a3.7,3.7,0,0,1-.267-1.168,2.4,2.4,0,0,1,2.5-2.434,2.451,2.451,0,0,1,2.434,1.9L64.687,29.4l3.737-10.374a2.571,2.571,0,0,1,2.435-1.935,2.363,2.363,0,0,1,2.409,2.335,3.834,3.834,0,0,1-.235,1.2L66.8,35.6c-1.5,3.636-3,5-6.038,5l-.16,0A7.612,7.612,0,0,1,57.982,40.143ZM44.363,30.1V21.63H44.2a2.169,2.169,0,0,1,0-4.337h.166V15.058a2.536,2.536,0,0,1,5.072,0v2.235H51.67a2.169,2.169,0,0,1,0,4.337H49.435v7.639c0,1.166.507,1.735,1.634,1.735.167,0,.507-.034.6-.034a2.114,2.114,0,0,1,2.1,2.1,2.063,2.063,0,0,1-1.3,1.9,7.474,7.474,0,0,1-2.9.507C46.465,35.473,44.363,34.238,44.363,30.1Zm-10.1,2.735V19.628a2.536,2.536,0,1,1,5.072,0V32.837a2.536,2.536,0,0,1-5.072,0Zm-9.254,0V25.2c0-2.4-1.134-3.637-3.069-3.637s-3.17,1.236-3.17,3.637v7.638a2.536,2.536,0,0,1-5.071,0V19.628a2.536,2.536,0,1,1,5.071,0v.2A6.318,6.318,0,0,1,24,16.96c3.837,0,6.072,2.536,6.072,6.638v9.239a2.536,2.536,0,0,1-5.072,0Zm9.032-20.012a2.757,2.757,0,1,1,2.757,2.757A2.757,2.757,0,0,1,34.037,12.825Zm-3.126-2.707a2.5,2.5,0,0,1-1.688-.655,18.661,18.661,0,0,0-12.516-4.45A18.657,18.657,0,0,0,4.195,9.46a2.506,2.506,0,1,1-3.378-3.7A23.708,23.708,0,0,1,16.707,0,23.709,23.709,0,0,1,32.6,5.762a2.5,2.5,0,0,1-1.688,4.356Z"
            transform="translate(2416.733 -365)"
            fill="#0b4569"
          />
        </g>
      </svg>
    </Box>
  );
}
