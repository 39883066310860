import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { PathwayState } from '../../@types/pathway';

// ----------------------------------------------------------------------

const initialState: PathwayState = {
  isLoading: false,
  error: false,
  pathways: [],
  myPathways: [],
  savedPathways: [],
  pathway: null,
  followingPathways: [],
  sharedPathways: [],
  // topic: null,
  // topics: [],
  publishedTopics: [],
  resources: [],
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 100
  // nextTopic: null
  // recallItems: null
  // step: 11
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // CLEAR POST
    // cleanTopics(state) {
    //   state.topics = [];
    // },

    cleanPathway(state) {
      state.pathway = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.pathways = action.payload;
    },

    noHasMore(state) {
      state.hasMore = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getOrganizationPathways(index: number, step: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('organization/pathways', { params: { index, step } });
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.items));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
