function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
// const ROOTS_BLOG = '/dashboard/blog/posts';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  newPassword: path(ROOTS_AUTH, '/new-password')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  blog: '/blog',
  student: '/student',
  professional: '/professional',
  organization: '/organization',
  institution: '/institution',
  selfTaught: '/self-taught',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  howItWorks: '/how-it-works'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    content: path(ROOTS_DASHBOARD, '/content'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    stats: path(ROOTS_DASHBOARD, '/user/stats'),
    report: path(ROOTS_DASHBOARD, '/user/report'),
    pathways: path(ROOTS_DASHBOARD, '/user/pathways')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/portfolio-review-is-this-portfolio-too-creative')
    // newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  explore: {
    root: path(ROOTS_DASHBOARD, '/explore'),
    following: path(ROOTS_DASHBOARD, '/explore/following'),
    pathway: path(ROOTS_DASHBOARD, '/explore/pathways/:title'),
    pathways: path(ROOTS_DASHBOARD, '/explore/pathways'),
    sharedWithYou: path(ROOTS_DASHBOARD, '/explore/sharedwithyou')
  },
  pathway: {
    new: path(ROOTS_DASHBOARD, '/pathway/new'),
    edit: path(ROOTS_DASHBOARD, '/pathway/edit/:pathwayId'),
    path: path(ROOTS_DASHBOARD, '/pathway')
  },
  pathways: {
    view: path(ROOTS_DASHBOARD, '/pathways/view')
  },
  overview: {
    root: path(ROOTS_DASHBOARD, '/overview')
  },
  recall: {
    root: path(ROOTS_DASHBOARD, '/recall'),
    agenda: path(ROOTS_DASHBOARD, '/recall/agenda')
  }
};

export const PATH_DOCS = 'https://minimal-docs.vercel.app/';
