import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { Topic, TopicState } from '../../@types/pathway';

const initialState: TopicState = {
  isLoading: false,
  error: false,
  topics: [],
  topic: null
};

const slice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    topicSaveSuccess(state, action) {
      state.topics = [...state.topics, action.payload];
    },
    topicUpdateSuccess(state, action) {
      const topics = state.topics.map((item) =>
        item.guid === action.payload.guid
          ? {
              ...item,
              title: action.payload.title,
              description: action.payload.description,
              color: action.payload.color,
              published: action.payload.published,
              url: action.payload.url,
              type: action.payload.type,
              resourceType: action.payload.resourceType
            }
          : item
      );
      state.topics = topics;
    },
    getTopics(state, action) {
      state.isLoading = false;
      state.topics = action.payload;
    },
    deleteTopicSuccess(state, action) {
      const idx = state.topics.map((item) => item.guid).indexOf(action.payload);
      state.topics = [...state.topics.slice(0, idx), ...state.topics.slice(idx + 1)];
    },
    updateTopicOrder(state, action) {
      state.isLoading = false;
      state.topics = action.payload;
    },
    resourceSaveSuccess(state, action) {
      const topicIndex = state.topics.findIndex((item) => item.guid === action.payload.topicGuid);

      if (!state.topics[topicIndex].resources) state.topics[topicIndex].resources = [];

      state.topics[topicIndex].resources.push(action.payload);
    },
    resourceUpdateSuccess(state, action) {
      const topics = state.topics.map((topic) => {
        if (topic.guid === action.payload.topicGuid) {
          topic.resources.map((resource) => {
            if (resource.guid === action.payload.guid) {
              resource.title = action.payload.title;
              resource.content = action.payload.content;
              resource.url = action.payload.url;
              resource.published = action.payload.published;
            }
            return resource;
          });
        }
        return topic;
      });
      state.topics = topics;
    },
    resourceDeleteSuccess(state, action) {
      const topics = state.topics.map((topic) =>
        // https://stackoverflow.com/questions/38375646/filtering-array-of-objects-with-arrays-based-on-nested-value
        ({
          ...topic,
          resources: topic.resources.filter((item) => item.guid !== action.payload.resourceGuid)
        })
      );
      state.topics = topics;
    },
    markTopicAsRead(state, action) {
      state.isLoading = false;
    },
    markTopicAsUnread(state, action) {
      state.isLoading = false;
    },
    markResourceAsRead(state, action) {
      state.isLoading = false;
    },
    markResourceAsUnread(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function saveTopic(pathwayGuid: string, topic: any) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.post(`Pathways/${pathwayGuid}/Topics`, {
        title: topic.title,
        description: topic.description,
        pathwayGuid,
        color: topic.color,
        url: topic.url,
        published: topic.publish,
        type: parseInt(topic.type, 10),
        resourceType: parseInt(topic.resourceType, 10)
      });
      dispatch(slice.actions.topicSaveSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updateTopic(pathwayGuid: string, topic: any) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.put(`Pathways/${pathwayGuid}/Topics`, {
        guid: topic.guid,
        title: topic.title,
        description: topic.description,
        color: topic.color,
        url: topic.url,
        published: topic.publish,
        type: parseInt(topic.type, 10),
        resourceType: parseInt(topic.resourceType, 10)
      });
      dispatch(slice.actions.topicUpdateSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function deleteTopic(topicGuid: string) {
  return async () => {
    const { dispatch } = store;
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`Pathways/1/Topics/${topicGuid}`);
      dispatch(slice.actions.deleteTopicSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getTopicsByPathwayId(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`Pathways/${pathwayGuid}/Topics`);
      dispatch(slice.actions.getTopics(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updateTopicOrder(pathwayGuid: string, topics: Topic[]) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateTopicOrder(topics));
      await axios.post(`Pathways/${pathwayGuid}/Topics/Reorganize`, {
        model: topics
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function saveResource(pathwayGuid: string, topicGuid: string, resource: any) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.post(`Pathways/${pathwayGuid}/Topics/${topicGuid}/Resources`, {
        title: resource.title,
        content: resource.content,
        url: resource.url,
        published: resource.published
      });
      dispatch(slice.actions.resourceSaveSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function updateResource(pathwayGuid: string, topicGuid: string, resource: any) {
  return async () => {
    const { dispatch } = store;
    try {
      const response = await axios.put(`Pathways/${pathwayGuid}/Topics/${topicGuid}/Resources`, {
        guid: resource.guid,
        title: resource.title,
        content: resource.content,
        url: resource.url,
        published: resource.published
      });
      dispatch(slice.actions.resourceUpdateSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function deleteResource(pathwayGuid: string, topicGuid: string, resourceGuid: string) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.delete(`Pathways/${pathwayGuid}/Topics/${topicGuid}/Resources/${resourceGuid}`);
      const obj = {
        pathwayGuid,
        topicGuid,
        resourceGuid
      };
      dispatch(slice.actions.resourceDeleteSuccess(obj));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function markTopicAsRead(topicGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Topics/${topicGuid}/MarkAsRead`);
      dispatch(slice.actions.markTopicAsRead(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markTopicAsUnread(topicGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Topics/${topicGuid}/MarkAsUnread`);
      dispatch(slice.actions.markTopicAsUnread(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markResourceAsRead(resourceGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Resources/${resourceGuid}/MarkAsRead`);
      dispatch(slice.actions.markResourceAsRead(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markResourceAsUnread(resourceGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Resources/${resourceGuid}/MarkAsUnread`);
      dispatch(slice.actions.markResourceAsUnread(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
