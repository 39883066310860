import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { RecallState } from '../../@types/recall';

const initialState: RecallState = {
  isLoading: false,
  error: null,
  items: []
};

const slice = createSlice({
  name: 'recall',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchRecallSuccess(state, action) {
      state.isLoading = false;
      state.items = [];
      state.items = [...action.payload.topics, ...action.payload.resources];
    },
    saveRecallSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function saveTopicRecall(topicGuid: string) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.post(`SpacedRepetition/CreateTopic/${topicGuid}`);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function saveResourceRecall(resourceGuid: string) {
  return async () => {
    const { dispatch } = store;
    try {
      await axios.post(`SpacedRepetition/CreateResource/${resourceGuid}`);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function fetchRecall() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('SpacedRepetition/GetDue');

      dispatch(slice.actions.fetchRecallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveRecallTopic(learningTopicGuid: string, recallOption: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `SpacedRepetition/ScheduleTopic/${learningTopicGuid}/${recallOption}`
      );
      dispatch(slice.actions.saveRecallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveRecallResource(learningResourceGuid: string, recallOption: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `SpacedRepetition/ScheduleResource/${learningResourceGuid}/${recallOption}`
      );
      dispatch(slice.actions.fetchRecallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
