import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';

// utils
import axios from '../../utils/axios';
// @types
import { DashboardState } from '../../@types/dashboard';

const initialState: DashboardState = {
  isLoading: false,
  error: false,
  topics: [],
  myProgress: []
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAllUnpublishedSuccess(state, action) {
      state.isLoading = false;
      state.topics = action.payload;
    },
    getMyProgress(state, action) {
      state.isLoading = false;
      state.myProgress = action.payload;
    }
  }
});

export default slice.reducer;

export function getAllUnpublished() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Dashboard/Topics/Unpublished');
      dispatch(slice.actions.getAllUnpublishedSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyProgress() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Dashboard/Pathways/Stats');
      dispatch(slice.actions.getMyProgress(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
