import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography } from '@material-ui/core';
// custom icons
import pathway, { getMyPathways } from 'redux/slices/pathway';
import Icon from '@iconify/react';
import MyCompany from 'components/MyCompany';
import { getRoleById } from 'utils/roles';
import {
  getCompanyPath,
  getEducationalInstitutionPath,
  getProfessionalPath,
  getStudentPath
} from 'utils/getImagePath';
import useLocales from 'hooks/useLocales';
import ShareIcon from '../../assets/icon_share';
import NoShareIcon from '../../assets/icon_noshare';
// hooks
import useAuth from '../../hooks/useAuth';
// @types
import { MyPathway, Pathway, PathwayState } from '../../@types/pathway';
import { Business, BusinessState } from '../../@types/business';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import SvgIconStyle from '../../components/SvgIconStyle';
//
import sidebarConfig from './SidebarConfig';
import { DocIcon } from '../../assets';
import Rolebar from './DashboardRoleSidebar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// const TopStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5)
// }));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5)
  // borderRadius: theme.shape.borderRadiusMd,
  // backgroundColor:
  //   theme.palette.mode === 'light'
  //     ? alpha(theme.palette.primary.main, 0.08)
  //     : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------
const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

const ICONS = {
  blog: getIcon('ic_blog'),
  pathway: getIcon('ic_pathways'),
  dashboard: getIcon('ic_dashboard')
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { myPathways } = useSelector((state: { pathway: PathwayState }) => state.pathway);

  const newPathway = () => {
    navigate(PATH_DASHBOARD.pathway.new);
  };

  const sidebarPathway = (pathways: MyPathway[]) => {
    const pws: any = [];
    const limit: number = 23;

    pathways.forEach((element) => {
      const row = {
        title:
          element.title.length > limit ? `${element.title.substring(0, limit)}...` : element.title,
        path: `${PATH_DASHBOARD.pathway.path}/${element.slug}`,
        icon: ICONS.pathway
      };
      pws.push(row);
    });

    const sidebarConfig = [
      {
        subheader: 'menu.my_pathways',
        items: [...pws]
      }
    ];
    return sidebarConfig;
  };

  const topSidebarPathway = (name: string, role: number) => {
    let subtitle: string = '';
    let icon: string = '';
    let path: string = '';

    icon = getCompanyPath();

    switch (role) {
      case 1:
        subtitle = 'menu.student';
        icon = getStudentPath();
        path = PATH_DASHBOARD.overview.root;
        break;
      case 2:
        subtitle = 'menu.professional';
        icon = getProfessionalPath();
        path = PATH_DASHBOARD.overview.root;
        break;
      case 3:
        subtitle = 'menu.institution';
        icon = getEducationalInstitutionPath();
        path = PATH_DASHBOARD.explore.pathways;
        break;
      case 4:
        subtitle = 'menu.organization';
        icon = getCompanyPath();
        path = PATH_DASHBOARD.explore.pathways;
        break;
      case 5:
        subtitle = 'menu.autodidact';
        icon = getCompanyPath();
        path = PATH_DASHBOARD.overview.root;
        break;
      default:
        break;
    }
    return (
      <Rolebar
        title={translate('menu.overview')}
        subtitle={translate(subtitle)}
        navigateTo={path}
        icon={icon}
      />
    );
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    dispatch(getMyPathways());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ pb: 0, pt: 2.5, pl: 2.5, pr: 2.5 }}>
        <Box
          component={RouterLink}
          to={isAuthenticated ? PATH_DASHBOARD.overview.root : '/'}
          sx={{ display: 'inline-flex' }}
        >
          <Logo />
        </Box>
      </Box>

      {user && topSidebarPathway(user?.name, user?.registeredRole)}

      <NavSection navConfig={sidebarConfig} />
      <NavSection navConfig={sidebarPathway(myPathways)} />

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ pb: 1 }} />
      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <DocIcon sx={{ width: 36, height: 36, mb: 2 }} />
          <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
            Hi, {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button fullWidth href={PATH_DOCS} target="_blank" variant="contained">
            Documentation
          </Button>
        </DocStyle>
      </Box> */}
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <Button fullWidth onClick={() => newPathway()} variant="contained">
            {translate('menu.new_pathway')}
          </Button>
        </DocStyle>
      </Box>
    </Scrollbar>
  );
  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
