// import { format, formatDistanceToNow} from 'date-fns';
import { format, formatDistanceToNow } from 'date-fns/esm';
import es from 'date-fns/locale/es';

export function fDate(date: string | number | Date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: string | number | Date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: string | number | Date) {
  const isValidDate = new Date(date);

  if (isValidDate.toLocaleString() === 'Invalid Date') {
    return date;
  }

  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: es
  });
}
