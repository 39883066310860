import axios from 'axios';
// import { baseUrlConfig } from 'config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://api-worknity.azurewebsites.net/'
      : 'https://localhost:5001/',
  headers: { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` }
});
// console.log('axiosInstance', localStorage.getItem('accessToken'));
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );
// based on https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const accessToken = window.localStorage.getItem('accessToken');
//       console.log('INTERCEPTOR TOKEN', accessToken);
//       axios.defaults.headers.common.Authorization = `${accessToken}`;
//       return axiosInstance(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );
// Based on https://stackoverflow.com/questions/65099767/how-to-setup-token-for-the-first-time-login-with-axios
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem('accessToken');
//   config.headers.Authorization = token ? `${token}` : null;
//   return config;
// });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('accessToken');
    // console.log('axiosinstance', token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
