import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { BlogState } from '../../@types/blog';

// ----------------------------------------------------------------------

const initialState: BlogState = {
  isLoading: false,
  error: false,
  posts: [],
  postsByUser: [],
  post: null,
  topic: null,
  topics: [],
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 100
  // step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // CLEAR POST
    clearTopics(state) {
      state.topics = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      // state.posts = [...state.posts, ...action.payload];
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    },

    // SAVE NEW POST
    postSaveSuccess(state, action) {
      state.isLoading = false;
      // state.post = action.payload;
      // state.posts = [...state.posts, action.payload];
      state.postsByUser = [...state.postsByUser, action.payload];
    },

    // SAVE NEW TOPIC
    topicSaveSuccess(state, action) {
      state.isLoading = false;
      // state.topic = action.payload;
      // state.topics = [...state.topics, ...action.payload];
      state.topics = [...state.topics, action.payload];
    },

    // UPDATE TOPIC
    topicUpdateSuccess(state, action) {
      state.isLoading = false;
      // state.topics = [...state.topics, action.payload];
      // const idx = state.topics.map((item) => item.guid).indexOf(action.payload);
      // state.topics = [
      //   ...state.topics.slice(0, idx),
      //   action.payload,
      //   ...state.topics.slice(idx + 1)
      // ];
      // https://medium.com/swlh/few-ways-to-update-a-state-array-in-redux-reducer-f2621ae8061
      // const index = state.topics.findIndex((x) => x.guid !== action.payload.guid);
      // console.log(index);
      // const newArray = [...state.topics];

      // newArray[index].title = action.payload.title;
      // newArray[index].description = action.payload.description;

      // return {
      //   ...state,
      //   topics: newArray
      // };
      const topics = state.topics.map((item) =>
        item.guid == action.payload.guid
          ? { ...item, title: action.payload.title, description: action.payload.description }
          : item
      );
      state.topics = topics;
    },

    // DELETE TOPIC
    deleteTopicSuccess(state, action) {
      const idx = state.topics.map((item) => item.guid).indexOf(action.payload);
      state.topics = [...state.topics.slice(0, idx), ...state.topics.slice(idx + 1)];
    },

    deleteSpaceSuccess(state, action) {
      const idx = state.postsByUser.map((item) => item.guid).indexOf(action.payload);
      state.postsByUser = [...state.postsByUser.slice(0, idx), ...state.postsByUser.slice(idx + 1)];
    },

    // GET TOPIC
    getTopics(state, action) {
      state.isLoading = false;
      state.topics = action.payload;
    },

    // GET POSTS BY USER
    getPostsInitialByUser(state, action) {
      state.isLoading = false;
      // state.posts = [...state.posts, ...action.payload];
      state.postsByUser = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/all');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index: number, step: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/blog/posts', {
      // const response = await axios.get('space/users', { params: { index, step } });
      const response = await axios.get('explore', { params: { index, step } });
      // const results = response.data.results.length;
      const results = response.data.items.length;
      // const { maxLength } = response.data;
      const { totalCount } = response.data;
      // dispatch(slice.actions.getPostsInitial(response.data.results));
      dispatch(slice.actions.getPostsInitial(response.data.items));

      // if (results >= maxLength) {
      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(pathwaySlug: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/blog/post', {
      //   params: { title }
      // });
      const response = await axios.get(`/pathways/${pathwaySlug}`);
      // dispatch(slice.actions.getPostSuccess(response.data.post));
      dispatch(slice.actions.getPostSuccess(response.data));
      // dispatch(getTopics(response.data.guid));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(title: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function savePost(post: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('pathways', {
        title: post.title,
        description: post.currentKnowledge,
        currentKnowledge: post.currentKnowledge,
        targetKnowledge: post.targetKnowledge,
        format: post.format,
        initial: parseInt(post.initial, 10),
        target: parseInt(post.target, 10),
        content: post.content,
        published: post.publish,
        enableComments: post.comments,
        public: post.public
      });
      // console.log(response);
      // dispatch(slice.actions.postSaveSuccess(response.data.savePost));
      dispatch(slice.actions.postSaveSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function saveTopic(pathwayGuid: string, topic: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`Pathways/${pathwayGuid}/Topics`, {
        title: topic.title,
        description: topic.description,
        pathwayGuid
      });
      // dispatch(slice.actions.topicSaveSuccess(response.data.saveTopic));
      dispatch(slice.actions.topicSaveSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTopic(pathwayGuid: string, topic: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`Pathways/${pathwayGuid}/Topics`, {
        guid: topic.guid,
        title: topic.title,
        description: topic.description
      });
      dispatch(slice.actions.topicUpdateSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteTopic(topicGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`Pathways/1/Topics/${topicGuid}`);
      console.log(response);
      dispatch(slice.actions.deleteTopicSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteSpace(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`Pathways/${pathwayGuid}`);
      dispatch(slice.actions.deleteSpaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ----------------------------------------------------------------------

export function getTopics(pathwayGuid: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.clearTopics());
    try {
      const response = await axios.get(`Pathways/${pathwayGuid}/Topics`);
      dispatch(slice.actions.getTopics(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

// ------------------------------

export function getPostsInitialByUser() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const at = localStorage.getItem('accessToken');
      // console.log('getPostsInitialByUser Token', at);
      // console.log('promise', await axios.get('space/user'));
      // const response = await axios.get('space/user');
      const response = await axios.get('pathways');
      const results = response.data.items.length;
      const { totalCount } = response.data;

      dispatch(slice.actions.getPostsInitialByUser(response.data.items));

      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------

export function clearTopics() {
  const { dispatch } = store;
  dispatch(slice.actions.clearTopics());
}
