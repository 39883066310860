import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Update = 'UPDATE',
  Verify = 'VERIFY'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.Verify]: {
    user: AuthUser;
  };
  [Types.Update]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user
      };

    case 'VERIFY':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken);

          // const response = await axios.get('/api/account/my-account');
          const response = await axios.get('user/account');
          const { user } = response.data;
          // console.log('user => ', user);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password
    // });

    // previous
    // const response = await axios.post('user/authenticate', { email, password });
    // const { accessToken, user } = response.data;
    // setSession(accessToken);
    // dispatch({
    //   type: Types.Login,
    //   payload: {
    //     user
    //   }
    // });
    await axios.post('user/authenticate', { email, password }).then((response) => {
      const { accessToken, user } = response.data;
      setSession(accessToken);
      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    role: string
  ) => {
    const response = await axios.post('user/register', {
      email,
      password,
      firstName,
      lastName,
      registeredRole: parseInt(role, 10)
    });
    const { user } = response.data;

    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const verify = async (email: string, code: string): Promise<boolean> => {
    const response = await axios.post('user/verifyAccount', {
      email,
      code
    });

    const { isValidCode } = response.data;

    return isValidCode;
    // dispatch({
    //   type: Types.Register,
    //   payload: {
    //     user
    //   }
    // });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = async (email: string) => {
    const response = await axios.post('user/forgotPassword', {
      email
    });

    console.log(response);
  };

  const newPassword = async (
    email: string,
    code: string,
    password: string,
    confirmPassword: string
  ) => {
    const response = await axios.post(`user/newPassword/${email}`, {
      code,
      password,
      confirmPassword
    });

    return response.data;
  };

  const updateProfile = async (
    email: string,
    firstName: string,
    lastName: string,
    displayName: string,
    about: string
  ) => {
    const response = await axios.put('user/update', {
      email,
      firstName,
      lastName,
      displayName,
      about
    });
    const { user } = response.data;

    dispatch({
      type: Types.Update,
      payload: {
        user
      }
    });
  };

  const updatePhotoUrl = async (email: string, photoUrl: string) => {
    const response = await axios.post('user/UpdatePhotoUrl', {
      email,
      photoUrl
    });
    const { user } = response.data;

    dispatch({
      type: Types.Update,
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        newPassword,
        register,
        resetPassword,
        updateProfile,
        updatePhotoUrl,
        verify
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
