import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios';
import { SuggestionState } from '../../@types/suggestion';

const initialState: SuggestionState = {
  isLoading: false,
  error: false,
  nextTopic: null
};

const slice = createSlice({
  name: 'suggestion',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNextTopic(state, action) {
      state.isLoading = false;
      state.nextTopic = action.payload;
    }
  }
});

export default slice.reducer;

export function getNextTopic() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('engagement/nextTopic');
      const result = response.data ? response.data : null;
      dispatch(slice.actions.getNextTopic(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
